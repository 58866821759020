<template>
  <v-card>
    <v-card-title class="justify-center text-center">
      Mapa de Vehículos por Calle
    </v-card-title>

    <v-card-text>
      <v-sheet
        v-if="loading"
        :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`"
        class="pa-3"
      >
        <v-progress-linear :indeterminate="true"></v-progress-linear>
        <v-skeleton-loader
          class="mx-auto"
          type="card-avatar"
        ></v-skeleton-loader>
      </v-sheet>
      <div>
        <v-alert
          v-if="error"
          outlined
          type="error"
          transition="scale-transition"
        >
          {{ error }}
        </v-alert>
        <mapa
          v-show="!loading && !error"
          ref="mapa"
          :map-config="mapConfig"
          api-key="AIzaSyBMAYXqqQ4ryrasH_5O5WkkrdFW6zH4cq4"
        ></mapa>
      </div>
    </v-card-text>
  </v-card>
</template>
<script>
import Mapa from './Mapa'

export default {
  components: {
    Mapa,
  },
  props: {
    datos: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      loading: true,
      error: false,
      mapConfig: {
        center: { lat: 20.885866, lng: -103.837593 },
        zoom: 16,
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        styles: [
          {
            featureType: 'all',
            elementType: 'geometry.fill',
            stylers: [
              {
                weight: '2.00',
              },
            ],
          },
          {
            featureType: 'all',
            elementType: 'geometry.stroke',
            stylers: [
              {
                color: '#9c9c9c',
              },
            ],
          },
          {
            featureType: 'all',
            elementType: 'labels.text',
            stylers: [
              {
                visibility: 'on',
              },
            ],
          },
          {
            featureType: 'landscape',
            elementType: 'all',
            stylers: [
              {
                color: '#f2f2f2',
              },
            ],
          },
          {
            featureType: 'landscape',
            elementType: 'geometry.fill',
            stylers: [
              {
                color: '#ffffff',
              },
            ],
          },
          {
            featureType: 'landscape.man_made',
            elementType: 'geometry.fill',
            stylers: [
              {
                color: '#ffffff',
              },
            ],
          },
          {
            featureType: 'poi',
            elementType: 'all',
            stylers: [
              {
                visibility: 'off',
              },
            ],
          },
          {
            featureType: 'road',
            elementType: 'all',
            stylers: [
              {
                saturation: -100,
              },
              {
                lightness: 45,
              },
            ],
          },
          {
            featureType: 'road',
            elementType: 'geometry.fill',
            stylers: [
              {
                color: '#eeeeee',
              },
            ],
          },
          {
            featureType: 'road',
            elementType: 'labels.text.fill',
            stylers: [
              {
                color: '#7b7b7b',
              },
            ],
          },
          {
            featureType: 'road',
            elementType: 'labels.text.stroke',
            stylers: [
              {
                color: '#ffffff',
              },
            ],
          },
          {
            featureType: 'road.highway',
            elementType: 'all',
            stylers: [
              {
                visibility: 'simplified',
              },
            ],
          },
          {
            featureType: 'road.arterial',
            elementType: 'labels.icon',
            stylers: [
              {
                visibility: 'off',
              },
            ],
          },
          {
            featureType: 'transit',
            elementType: 'all',
            stylers: [
              {
                visibility: 'off',
              },
            ],
          },
          {
            featureType: 'water',
            elementType: 'all',
            stylers: [
              {
                color: '#46bcec',
              },
              {
                visibility: 'on',
              },
            ],
          },
          {
            featureType: 'water',
            elementType: 'geometry.fill',
            stylers: [
              {
                color: '#c8d7d4',
              },
            ],
          },
          {
            featureType: 'water',
            elementType: 'labels.text.fill',
            stylers: [
              {
                color: '#070707',
              },
            ],
          },
          {
            featureType: 'water',
            elementType: 'labels.text.stroke',
            stylers: [
              {
                color: '#ffffff',
              },
            ],
          },
        ],
      },
      streetData: {
        'CALLE JUÁREZ': {
          total: 0,
          color: 'Goldenrod',
          label: 'calle juárez',
          classStyle: 'map-icon-calle-juarez',
          coordenadas: [
            [20.887084, -103.837350],
            [20.886744, -103.837549],
            [20.886524, -103.837688],
            [20.886434, -103.837731],
            [20.886274, -103.837806],
            [20.884897, -103.838819],
            [20.885974, -103.837962],
            [20.885839, -103.838021],
            [20.885609, -103.838149],
            [20.885534, -103.838202],
            [20.884547, -103.838968],
            [20.884397, -103.839028],
            [20.885174, -103.838461],
            [20.884216, -103.839103],
            [20.884005, -103.839183],
            [20.883744, -103.839285],
            [20.883579, -103.839349],

          ],
        },
        'CALLE MADERO': {
          total: 0,
          color: 'gray',
          label: 'calle francisco i. madero',
          classStyle: 'map-icon-calle-madero',
          coordenadas: [
            [20.879960, -103.833135],
            [20.880130, -103.833360],
            [20.880291, -103.833575],
            [20.880481, -103.833843],
            [20.880671, -103.834090],
            [20.880841, -103.834336],
            [20.880981, -103.834572],
            [20.881064, -103.834715],
            [20.881094, -103.834725],
            [20.881234, -103.834961],
            [20.881414, -103.835186],
            [20.881624, -103.835411],
            [20.881804, -103.835625],
            [20.882055, -103.835850],
            [20.882365, -103.836139],
            [20.882495, -103.836418],
            [20.882724, -103.836708],
            [20.882714, -103.836987],
            [20.882794, -103.837266],
            [20.882874, -103.837610],
            [20.882964, -103.837867],
            [20.883064, -103.838189],
            [20.883154, -103.838478],
            [20.883244, -103.838756],
            [20.883324, -103.838992],
            [20.883334, -103.839110],
            [20.883434, -103.839346],
          ],
        },
        'CALLE SIXTO GORJÓN': {
          total: 0,
          color: 'red',
          label: 'calle sixto gorjón',
          classStyle: 'map-icon-calle-sixto',
          coordenadas: [
            [20.882179, -103.833144],
            [20.882209, -103.833337],
            [20.882349, -103.833638],
            [20.882419, -103.833949],
            [20.882509, -103.834195],
            [20.882589, -103.834506],
            [20.882709, -103.834795],
            [20.882759, -103.835117],
            [20.882869, -103.835375],
            [20.882959, -103.835664],
            [20.883049, -103.835932],
            [20.883159, -103.836211],
            [20.883299, -103.836587],
            [20.883399, -103.836801],
            [20.883529, -103.837069],
            [20.883649, -103.837316],
            [20.883759, -103.837594],
            [20.883879, -103.837894],
            [20.883959, -103.838172],
            [20.884099, -103.838494],
            [20.884169, -103.838741],
            [20.884199, -103.838891],
          ],
        },
        'CALLE RAMÓN CORONA': {
          total: 0,
          color: 'black',
          label: 'calle ramón corona',
          classStyle: 'map-icon-calle-ramon',
          coordenadas: [
            [20.888851, -103.838089],
            [20.888681, -103.837810],
            [20.888461, -103.837782],
            [20.888251, -103.837964],
            [20.887971, -103.838104],
            [20.887725, -103.838306],
            [20.887505, -103.838456],
            [20.887275, -103.838627],
            [20.887005, -103.838788],
            [20.886745, -103.838896],
            [20.886455, -103.839014],
            [20.886195, -103.839154],
            [20.885985, -103.839293],
            [20.885795, -103.839368],
            [20.885555, -103.839486],
            [20.885285, -103.839626],
            [20.885228, -103.839658],
            [20.884897, -103.839851],
            [20.884497, -103.840022],
            [20.884357, -103.840076],
            [20.884248, -103.840121],
            [20.883868, -103.840389],
          ],
        },
      },
    }
  },
  mounted() {
  },
  inject: {
    theme: {
      default: { isDark: false },
    },
  },
  methods: {
    fillData(datos) {
      this.loading = false
      this.$refs.mapa.mapeoDatosMapaBD(datos, this.streetData)
    },
    setLoading() {
      this.loading = true
      this.error = false
    },
    setError(error) {
      this.loading = false
      this.error = error
    },
  },
}
</script>
