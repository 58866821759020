<template>
  <v-card>
    <v-card-title class="justify-center text-center">
      Número Total de <br>Personas<br> por Calle
    </v-card-title>
    <v-card-subtitle
      v-if="total !== 0"
      class="justify-center text-center font-weight-bold text-primary"
    >
      {{ total | numberFormat }} Personas
    </v-card-subtitle>
    <v-card-text>
      <v-sheet
        v-if="loading"
        :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`"
        class="pa-3"
      >
        <v-progress-linear :indeterminate="true"></v-progress-linear>
        <v-skeleton-loader
          class="mx-auto"
          type="card-avatar"
        ></v-skeleton-loader>
      </v-sheet>
      <v-alert
        v-if="error"
        outlined
        type="error"
        transition="scale-transition"
      >
        Error en el servicio {{ error }}
      </v-alert>
      <div
        v-if="detalle && !loading"
        class="small"
      >
        <pie-chart
          v-if="datacollection"
          :chart-data="datacollection"
        ></pie-chart>
      </div>
    </v-card-text>
  </v-card>
</template>
<script>
import PieChart from './PieChart'

export default {
  components: {
    PieChart,
  },
  props: {
    fecha: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      loading: true,
      datacollection: null,
      detalle: [],
      total: 0,
      error: false,
      errMessage: '',
    }
  },
  mounted() {
  },
  inject: {
    theme: {
      default: { isDark: false },
    },
  },
  methods: {
    setLoading() {
      this.loading = true
    },
    fillData(detalle, total) {
      this.detalle = detalle
      this.total = total
      const data = {
        labels: [],
        datasets: [
          {
            data: [],
            backgroundColor: ['#028675', '#D68903', '#0F3E8E'],
          },
        ],
      }
      this.detalle.sort((a, b) => {
        if (a.total > b.total) {
          return -1
        }
        if (a.total < b.total) {
          return 1
        }

        return 0
      })
      this.detalle.forEach(e => {
        data.labels.push(e.calle)
        data.datasets[0].data.push(e.total)
      })

      this.datacollection = data
      this.loading = false
    },
    setError(error) {
      this.loading = false
      this.error = error
    },
  },
}
</script>
