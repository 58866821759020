<template>
  <v-card>
    <v-card-title class="justify-center text-center">
      Mapa de Personas por Calle
    </v-card-title>

    <v-card-text>
      <v-sheet
        v-if="loading"
        :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`"
        class="pa-3"
      >
        <v-progress-linear :indeterminate="true"></v-progress-linear>
        <v-skeleton-loader
          class="mx-auto"
          type="card-avatar"
        ></v-skeleton-loader>
      </v-sheet>
      <div>
        <v-alert
          v-if="error"
          outlined
          type="error"
          transition="scale-transition"
        >
          {{ error }}
        </v-alert>
        <mapa
          v-show="!loading && !error"
          ref="mapa"
          :map-config="mapConfig"
          api-key="AIzaSyBMAYXqqQ4ryrasH_5O5WkkrdFW6zH4cq4"
        ></mapa>
      </div>
    </v-card-text>
  </v-card>
</template>
<script>
import Mapa from './Mapa'

export default {
  components: {
    Mapa,
  },
  props: {
    datos: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      loading: true,
      error: false,
      errMessage: null,
      mapConfig: {
        center: { lat: 20.885866, lng: -103.837593 },
        zoom: 16,
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        styles: [
          {
            featureType: 'all',
            elementType: 'geometry.fill',
            stylers: [
              {
                weight: '2.00',
              },
            ],
          },
          {
            featureType: 'all',
            elementType: 'geometry.stroke',
            stylers: [
              {
                color: '#9c9c9c',
              },
            ],
          },
          {
            featureType: 'all',
            elementType: 'labels.text',
            stylers: [
              {
                visibility: 'on',
              },
            ],
          },
          {
            featureType: 'landscape',
            elementType: 'all',
            stylers: [
              {
                color: '#f2f2f2',
              },
            ],
          },
          {
            featureType: 'landscape',
            elementType: 'geometry.fill',
            stylers: [
              {
                color: '#ffffff',
              },
            ],
          },
          {
            featureType: 'landscape.man_made',
            elementType: 'geometry.fill',
            stylers: [
              {
                color: '#ffffff',
              },
            ],
          },
          {
            featureType: 'poi',
            elementType: 'all',
            stylers: [
              {
                visibility: 'off',
              },
            ],
          },
          {
            featureType: 'road',
            elementType: 'all',
            stylers: [
              {
                saturation: -100,
              },
              {
                lightness: 45,
              },
            ],
          },
          {
            featureType: 'road',
            elementType: 'geometry.fill',
            stylers: [
              {
                color: '#eeeeee',
              },
            ],
          },
          {
            featureType: 'road',
            elementType: 'labels.text.fill',
            stylers: [
              {
                color: '#7b7b7b',
              },
            ],
          },
          {
            featureType: 'road',
            elementType: 'labels.text.stroke',
            stylers: [
              {
                color: '#ffffff',
              },
            ],
          },
          {
            featureType: 'road.highway',
            elementType: 'all',
            stylers: [
              {
                visibility: 'simplified',
              },
            ],
          },
          {
            featureType: 'road.arterial',
            elementType: 'labels.icon',
            stylers: [
              {
                visibility: 'off',
              },
            ],
          },
          {
            featureType: 'transit',
            elementType: 'all',
            stylers: [
              {
                visibility: 'off',
              },
            ],
          },
          {
            featureType: 'water',
            elementType: 'all',
            stylers: [
              {
                color: '#46bcec',
              },
              {
                visibility: 'on',
              },
            ],
          },
          {
            featureType: 'water',
            elementType: 'geometry.fill',
            stylers: [
              {
                color: '#c8d7d4',
              },
            ],
          },
          {
            featureType: 'water',
            elementType: 'labels.text.fill',
            stylers: [
              {
                color: '#070707',
              },
            ],
          },
          {
            featureType: 'water',
            elementType: 'labels.text.stroke',
            stylers: [
              {
                color: '#ffffff',
              },
            ],
          },
        ],
      },
      streetData: {
        'Calle Perpendicular a Cholula': {
          total: 0,
          color: 'black',
          label: 'calle ramón corona',
          classStyle: 'map-icon-calle-ramon',
          coordenadas: [
            [20.888851, -103.838089],
            [20.888681, -103.837810],
            [20.888461, -103.837782],
            [20.888251, -103.837964],
            [20.887971, -103.838104],
            [20.887725, -103.838306],
            [20.887505, -103.838456],
            [20.887275, -103.838627],
            [20.887005, -103.838788],
            [20.886745, -103.838896],
            [20.886455, -103.839014],
            [20.886195, -103.839154],
            [20.885985, -103.839293],
            [20.885795, -103.839368],
            [20.885555, -103.839486],
            [20.885285, -103.839626],
            [20.885228, -103.839658],
            [20.884897, -103.839851],
            [20.884497, -103.840022],
            [20.884357, -103.840076],
            [20.884248, -103.840121],
            [20.883868, -103.840389],
          ],
        },
        'Calle Paralela a Cholula': {
          total: 0,
          color: 'blue',
          label: 'calle josé cuervo',
          classStyle: 'map-icon-calle-cholula',
          coordenadas: [
            [20.885219, -103.839890],
            [20.885327, -103.840072],
            [20.885392, -103.840211],
            [20.885465, -103.840372],
            [20.885560, -103.840576],
            [20.885611, -103.840688],
            [20.885702, -103.840873],
            [20.885770, -103.841023],
            [20.885825, -103.841135],
            [20.885885, -103.841239],
            [20.885935, -103.841357],
            [20.885985, -103.841460],
            [20.886043, -103.841594],
            [20.886141, -103.841824],
          ],
        },
        Cholula: {
          total: 0,
          color: '#815e46',
          label: 'fuera fonda cholula',
          classStyle: 'map-icon-calle-fonda-cholula',
          coordenadas: [
            [20.885299, -103.839844],
            [20.885309, -103.839785],
            [20.885364, -103.839718],
          ],
        },
      },
    }
  },
  mounted() {
  },
  inject: {
    theme: {
      default: { isDark: false },
    },
  },
  methods: {
    fillData(datos) {
      this.loading = false
      this.$refs.mapa.mapeoDatosMapaBD(datos, this.streetData)
    },
    setLoading() {
      this.loading = true
      this.error = false
    },
    setError(error) {
      this.loading = false
      this.error = error
    },
  },
}
</script>
