<template>
  <v-card>
    <v-card-title class="justify-center text-center">
      Valor Máximo de <br>Vehículos<br> en el Día
    </v-card-title>

    <v-card-text>
      <v-sheet
        v-if="loading"
        :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`"
        class="pa-3"
      >
        <v-progress-linear :indeterminate="true"></v-progress-linear>
        <v-skeleton-loader
          class="mx-auto"
          type="card-avatar"
        ></v-skeleton-loader>
      </v-sheet>
      <div
        v-if="series.length > 0 && !loading"
        id="chart"
      >
        <apexchart
          type="radialBar"
          height="350"
          :options="chartOptions"
          :series="series"
        ></apexchart>
      </div>
    </v-card-text>
  </v-card>
</template>
<script>
import apexchart from 'vue-apexcharts'

export default {
  components: {
    apexchart,
  },
  props: {
  },
  data() {
    return {
      loading: true,
      datacollection: null,
      error: false,
      errMessage: '',
      series: [],
      chartOptions: {},
    }
  },
  mounted() {
  },
  inject: {
    theme: {
      default: { isDark: false },
    },
  },
  methods: {
    setLoading() {
      this.loading = true
      this.series = []
      this.chartOptions = {}
    },
    fillData(maxData) {
      this.series.push((maxData / 2000) * 100)
      this.chartOptions = {
        chart: {
          height: 350,
          type: 'radialBar',
          offsetY: -10,
        },
        colors: [],
        plotOptions: {
          radialBar: {
            startAngle: -135,
            endAngle: 135,
            dataLabels: {
              name: {
                fontSize: '16px',
                color: undefined,
                offsetY: 120,
              },
              value: {
                offsetY: 76,
                fontSize: '22px',
                color: '#028675',
                formatter() {
                  return `${maxData}`
                },
              },
            },
          },
        },
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'dark',
            shadeIntensity: 0.15,
            inverseColors: false,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 50, 65, 2000],
          },
        },
        stroke: {
          dashArray: 4,
        },
        labels: ['Vehiculos'],
      }
      let color = '#028675'
      if (maxData <= 1000) {
        color = 'rgba(2, 134, 117, 0.5)'
      } else if (maxData >= 1000 && maxData <= 1500) {
        color = 'rgba(238, 232, 16, 0.5)'
      } else if (maxData >= 1500) {
        color = 'rgba(238, 36, 16, 0.5)'
      }

      this.chartOptions.colors.push(color)
      this.loading = false
    },
  },
}
</script>
