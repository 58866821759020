<template>
  <v-card>
    <v-card-title class="justify-center text-center">
      Afluencia Vehicular por Periodo Seleccionado
    </v-card-title>

    <v-card-text>
      <v-sheet
        v-if="loading"
        :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`"
        class="pa-3"
      >
        <v-progress-linear :indeterminate="true"></v-progress-linear>
        <v-skeleton-loader
          class="mx-auto"
          type="card-avatar"
        ></v-skeleton-loader>
      </v-sheet>
      <v-alert
        v-if="error"
        outlined
        type="error"
        transition="scale-transition"
      >
        Error en el servicio {{ error }}
      </v-alert>
      <div
        v-if="!loading"
        class="small"
      >
        <line-chart
          v-if="datacollection"
          :chart-data="datacollection"
          :limits="limits"
        ></line-chart>
      </div>
    </v-card-text>
  </v-card>
</template>
<script>
import LineChart from './LineChart'

export default {
  components: {
    LineChart,
  },
  props: {
  },
  data() {
    return {
      loading: true,
      datacollection: null,
      error: false,
      errMessage: '',
      limits: {
        inf: 1000,
        medio: 1500,
        sup: 2000,
      },
    }
  },
  mounted() {
  },
  inject: {
    theme: {
      default: { isDark: false },
    },
  },
  methods: {
    setLoading() {
      this.loading = true
    },
    fillData(detalle) {
      this.detalle = detalle
      const data = {
        labels: [],
        datasets: [
          {
            data: [],
            borderColor: '#FFF',
            fill: false,
          },
        ],
      }
      this.detalle.forEach(e => {
        data.labels.push(e.hr)
        data.datasets[0].data.push(e.total)
      })
      this.datacollection = data
      this.loading = false
    },
    setError(error) {
      this.loading = false
      this.error = error
    },
  },
}
</script>
