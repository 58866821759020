<template>
  <div>
    <div
      v-show="map"
      ref="googleMap"
      class="google-map"
    ></div>
  </div>
</template>

<script>
import { Loader } from '@googlemaps/js-api-loader'

export default {
  props: {
    mapConfig: {
      type: Object,
      default() {
        return {}
      },
    },
    apiKey: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      google: null,
      map: null,
      total: 0,
    }
  },

  async mounted() {
    const loader = new Loader({
      apiKey: this.apiKey,
      libraries: ['visualization'],
    })

    // Promise
    loader
      .load()
      .then(google => {
        this.google = google
      })
      .catch(e => {
        // do something
        console.log(e)
      })
  },

  methods: {
    initMap() {
      if (!this.map && this.google) {
        const mapContainer = this.$refs.googleMap
        this.map = new this.google.maps.Map(mapContainer, this.mapConfig)
      }
    },
    mapeoDatosMapaBD(data, dataInterno) {
      this.initMap()

      // suma los total de las avenidas
      data.forEach(item => {
        this.total += Number(item.total)
      })

      /**
       * ordena el total de mayor a menor
       */
      data.sort((a, b) => {
        const an = Number(a.total)
        const bn = Number(b.total)
        if (an > bn) {
          return -1
        }
        if (an < bn) {
          return 1
        }

        return 0
      })

      /**
       * mapea los elementos de la BD con el array de coordenadas
       */
      data.forEach(element => {
        const nameOriginalBD = element.calle
        if (dataInterno[nameOriginalBD]) {
          // eslint-disable-next-line no-param-reassign
          dataInterno[nameOriginalBD].total = element.total
        }
      })
      this.createMarkers(dataInterno)
    },
    createMarkers(data) {
      const heatmapData = []
      Object.keys(data).forEach(k => {
        const item = data[k]
        item.coordenadas.forEach(coor => {
          const gcoord = new this.google.maps.LatLng(coor[0], coor[1])

          heatmapData.push(gcoord)
        })
      })
      const heatmap = new this.google.maps.visualization.HeatmapLayer({
        data: heatmapData,
      })
      heatmap.set('radius', 30)
      heatmap.setMap(this.map)
    },
  },
}
</script>
<style>
.google-map {
  min-height: 300px;
}
</style>
