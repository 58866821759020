<template>
  <div>
    <v-row>
      <v-col
        cols="12"
        offset="8"
        md="4"
      >
        <v-menu
          ref="fechaLocal"
          v-model="fechaLocal"
          :close-on-content-click="false"
          :return-value.sync="date"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="dateFormatted"
              persistent-hint
              :prepend-inner-icon="icons.mdiCalendar"
              v-bind="attrs"
              outlined
              readonly
              dense
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="date"
            no-title
            range
            locale="es-MX"
            :max="maxDate"
          >
            <v-spacer></v-spacer>
            <v-btn
              text
              color="primary"
              @click="fechaLocal = false"
            >
              Cancel
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="searchData(date)"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
    </v-row>
    <div v-if="errorMovilidad">
      <v-alert
        outlined
        type="error"
        transition="scale-transition"
      >
        Error en el servicio {{ errorMovilidad }}
      </v-alert>
    </div>

    <div v-show="!errorMovilidad">
      <v-row>
        <v-col
          cols="12"
          md="3"
        >
          <total-vehiculos-tipo ref="totalVehiculos"></total-vehiculos-tipo>
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <afluencia-vehicular ref="afluenciaVehicular"></afluencia-vehicular>
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <valor-maximo-vehiculos ref="vMaxVehiculos"></valor-maximo-vehiculos>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          md="3"
        >
          <total-personas ref="totalPersonas"></total-personas>
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <afluencia-peatonal ref="afluenciaPeatonal"></afluencia-peatonal>
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <valor-maximo-personas ref="vMaxPersonas"></valor-maximo-personas>
        </v-col>
      </v-row>
    </div>
    <v-row>
      <v-col
        cols="12"
        md="6"
      >
        <vehiculos-por-calle ref="vehiculosCalle"></vehiculos-por-calle>
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <personas-por-calle ref="personasCalle"></personas-por-calle>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        md="6"
      >
        <vehiculos-por-calle-chart ref="numVehiculos"></vehiculos-por-calle-chart>
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <personas-por-calle-chart ref="numPersonas"></personas-por-calle-chart>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mdiCalendar } from '@mdi/js'
import moment from 'moment'
import 'moment-timezone'
import service from '@/services/movilidad'
import AfluenciaPeatonal from './AfluenciaPeatonal'
import AfluenciaVehicular from './AfluenciaVehicular'
import PersonasPorCalle from './PersonasPorCalle'
import TotalPersonas from './TotalPersonas'
import TotalVehiculosTipo from './TotalVehiculosTipo'
import ValorMaximoPersonas from './ValorMaximoPersonas'
import ValorMaximoVehiculos from './ValorMaximoVehiculos'
import VehiculosPorCalle from './VehiculosPorCalle'
import PersonasPorCalleChart from './PersonasPorCalleChart'
import VehiculosPorCalleChart from './VehiculosPorCalleChart'
import auth from '@/services/auth'

export default {
  components: {
    TotalVehiculosTipo,
    AfluenciaVehicular,
    ValorMaximoVehiculos,
    TotalPersonas,
    AfluenciaPeatonal,
    ValorMaximoPersonas,
    VehiculosPorCalle,
    PersonasPorCalle,
    PersonasPorCalleChart,
    VehiculosPorCalleChart,
  },
  data: () => ({
    datos: null,
    errorMovilidad: null,
    maxVehiculos: 0,
    maxPersonas: 0,
    date: [moment().tz('America/Mexico_City').format('YYYY-MM-DD'), moment().tz('America/Mexico_City').format('YYYY-MM-DD')],
    dateFormatted: moment().tz('America/Mexico_City').format('D MMMM YYYY'),
    fechaLocal: '',
    maxDate: moment().tz('America/Mexico_City').format('YYYY-MM-DD'),
    fecha: {
      fechaInicio: moment().tz('America/Mexico_City').format('YYYY-MM-DD'),
      fechaFin: moment().tz('America/Mexico_City').format('YYYY-MM-DD'),
    },
  }),
  computed: {
    computedDateFormatted() {
      return this.formatDate(this.date)
    },
    isLoggedIn() {
      return auth.isLoggin()
    },
  },

  watch: {
    date() {
      this.dateFormatted = this.formatDate(this.date)
    },
  },
  mounted() {
    if (!this.isLoggedIn) {
      this.$router.push(`/pages/login?return=${this.$route.fullPath}`)
    }
    this.getData()
  },
  methods: {
    searchData(date) {
      this.$refs.fechaLocal.save(date)
      this.getData()
    },
    formatDate(date) {
      if (!date) return null
      const ndate = [...date].sort()
      let f = moment(ndate[0]).format('D MMMM YYYY')
      // eslint-disable-next-line prefer-destructuring
      this.fecha.fechaInicio = ndate[0]
      // eslint-disable-next-line prefer-destructuring
      this.fecha.fechaFin = ndate[0]
      if (date.length > 1) {
        f += ` a ${moment(ndate[1]).format('D MMMM YYYY')}`
        // eslint-disable-next-line prefer-destructuring
        this.fecha.fechaFin = ndate[1]
      }

      return f
    },
    getData() {
      this.errorMovilidad = false
      this.$refs.vehiculosCalle.setLoading()
      this.$refs.personasCalle.setLoading()
      this.$refs.totalVehiculos.setLoading()
      this.$refs.afluenciaVehicular.setLoading()
      this.$refs.totalPersonas.setLoading()
      this.$refs.afluenciaPeatonal.setLoading()
      this.$refs.vMaxVehiculos.setLoading()
      this.$refs.vMaxPersonas.setLoading()
      this.$refs.numPersonas.setLoading()
      this.$refs.numVehiculos.setLoading()

      service.getMovilidad(this.fecha).then(resp => {
        this.datos = resp.data
        if (this.datos.error) {
          this.errorMovilidad = this.datos.error
        } else {
          if (this.datos?.detallehrsvehiculos && this.datos?.detallehrsvehiculos?.length) {
            this.datos.detallehrsvehiculos.forEach(e => {
              if (this.maxVehiculos <= e.total) {
                this.maxVehiculos = e.total
              }
            })
            this.$refs.afluenciaVehicular.fillData(this.datos.detallehrsvehiculos)
            if (this.datos?.totalvehiculos) {
              this.$refs.totalVehiculos.fillData(this.datos.detallevehiculos, this.datos.totalvehiculos)
            } else {
              this.$refs.totalVehiculos.setError('Sin Datos')
            }
          } else {
            this.$refs.afluenciaVehicular.setError('Sin Datos')
            this.$refs.totalVehiculos.setError('Sin Datos')
          }
          if (this.datos?.detallehrspersonas && this.datos?.detallehrspersonas?.length) {
            this.datos.detallehrspersonas.forEach(e => {
              if (this.maxPersonas <= e.total) {
                this.maxPersonas = e.total
              }
            })
            this.$refs.afluenciaPeatonal.fillData(this.datos.detallehrspersonas)
            if (this.datos?.totalpersonas) {
              this.$refs.totalPersonas.fillData(this.datos.detallepersonas, this.datos.totalpersonas)
            } else {
              this.$refs.totalPersonas.setError('Sin Datos')
            }
          } else {
            this.$refs.afluenciaPeatonal.setError('Sin Datos')
            this.$refs.totalPersonas.setError('Sin Datos')
          }
          this.$refs.vMaxVehiculos.fillData(this.maxVehiculos)
          this.$refs.vMaxPersonas.fillData(this.maxPersonas)
        }
      }).catch(err => {
        this.errorMovilidad = err.response?.data
      })
      service.getAvenidas(this.fecha).then(resp => {
        if (resp.data.error) {
          this.$refs.vehiculosCalle.setError(resp.data.error)
          this.$refs.personasCalle.setError(resp.data.error)
          this.$refs.numPersonas.setError(resp.data.error)
          this.$refs.numVehiculos.setError(resp.data.error)
        } else {
          if (resp.data?.calleautos) {
            const autos = resp.data.calleautos
            this.$refs.vehiculosCalle.fillData(autos)
            this.$refs.numVehiculos.fillData(autos)
          } else {
            this.$refs.vehiculosCalle.setError(resp.data.error)
            this.$refs.numVehiculos.setError(resp.data.error)
          }
          if (resp.data?.callepersonas) {
            const personas = resp.data.callepersonas
            this.$refs.personasCalle.fillData(personas)
            this.$refs.numPersonas.fillData(personas)
          } else {
            this.$refs.personasCalle.setError(resp.data.error)
            this.$refs.numPersonas.setError(resp.data.error)
          }
        }
      }).catch(err => {
        this.$refs.vehiculosCalle.setError(err)
        this.$refs.personasCalle.setError(err)
        this.$refs.numPersonas.setError(err)
        this.$refs.numVehiculos.setError(err)
      })
    },
  },

  setup() {
    moment.locale('es')

    return {
      icons: {
        mdiCalendar,
      },
    }
  },
}
</script>
