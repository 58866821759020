import axios from 'axios'
import environment from '@/environments/environment'
import auth from './auth'

const path = environment.apiUrl

export default {
  getMovilidad(fecha) {
    return axios.post(`${path}/movilidad`, fecha, {
      headers: {
        Authorization: `Bearer ${auth.getToken()}`,
        'Content-Type': 'application/json',
      },
    })
  },
  getAvenidas(fecha) {
    return axios.post(`${path}/avenidas_principales`, fecha, {
      headers: {
        Authorization: `Bearer ${auth.getToken()}`,
        'Content-Type': 'application/json',
      },
    })
  },

}
